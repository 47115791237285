export default {
    ignoreQuestion: {
        ignore: {
            DE: 'Frage ignorieren',
            EN: 'ignore'
        },
        unignore: {
            DE: 'Frage einblenden',
            EN: 're-enable'
        }
    },
    note: {
        DE: 'Notiz',
        EN: 'note'
    },
    rangeSlider: {
        doesNotApply: {
            DE: 'Trifft überhaupt nicht zu',
            EN: 'Does not apply at all'
        },
        doesApply: {
            DE: 'Trifft vollkommen zu',
            EN: 'fully applies'
        }
    },
    navigation: {
        reliability: {
            DE: 'Zuverlässigkeit',
            EN: 'Reliability'
        },
        security: {
            DE: 'Sicherheit',
            EN: 'Security'
        },
        costOptimization: {
            DE: 'Kostenoptimierung',
            EN: 'Cost Optimization'
        },
        operationelExcellence: {
            DE: 'Operationale Exzellenz',
            EN: 'Operational Excellence'
        },
        performanceEfficiency: {
            DE: 'Leistungseffizienz',
            EN: 'Performance Efficiency'
        },
        totalRating: {
            DE: 'Gesamtwertung',
            EN: 'Total Score'
        }
    },
    print: {
        DE: 'Drucken',
        EN: 'print'
    },
    downloadConfig: {
        DE: 'Konfiguration herunterladen',
        EN: 'save configuration'
    }
}