import React, { useEffect, useState } from 'react';
import { msalConfig, loginRequest } from '../config/auth.config';
import { PublicClientApplication } from '@azure/msal-browser';

interface AuthGuardProps {
  children: React.ReactNode;
}

/**wrapper component that uses microsoft authentication against p3 tenant. Displays child components if succesful, blocks view otherwise
*/
const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const msalInstance = new PublicClientApplication(msalConfig);

  //msalInstance must be initialized first before trying to login
  const tryLogin = async () => {
    try {
      await msalInstance.loginPopup(loginRequest).then(() => { //discard auth result since not needed at the moment
        setIsAuthenticated(true);
      })
    } catch (error) {
      //TODO unified error handling + messages for the end user in next iterations of the prototype
      console.error(error);
    }
  }

  //happens once on startup, after the component is rendered
  useEffect(() => {
    const loginIfNecessary = async () => {
      await msalInstance.initialize();
      const accounts = msalInstance.getAllAccounts();
      if (!!accounts?.length) {
        setIsAuthenticated(true);
      } else {
        tryLogin();
      }
    };

    loginIfNecessary();
  }, []);

  return isAuthenticated ? 
        <>{children}</>
      : (
        <div className="min-h-[80vh] p-8 mx-auto bg-white rounded-xl shadow-md flex items-center">
          <div className='flex flex-col text-center items-center mx-auto space-y-[var(--spacing-sm)] max-w-[50vw]'>
            <img className="" src="p3.png" width="100px" />
            <h1 className="text-xl font-medium text-black">Du musst eingeloggt sein, um dieses Tool zu nutzen.</h1>
            <p className="text-gray-500">Bitte melde dich an, um fortzufahren. Falls sich kein Login Fenster öffnet, bitte überprüfe, ob Popup Fenster auf dieser Website blockiert werden.</p>
            <button
              onClick={tryLogin}
            >
              Anmelden
            </button>
          </div>
        </div>
      )
}

export default AuthGuard;
