import { useContext } from "react";
import AppContext from "../../state/AppContext";
import DonutChart from "./donutChart.component";
import useScoreCalculation, { getMatchingDomain } from "./useScoreCalculation";
import FrameworkDomain from "../FrameworkDomain";


interface AuditResultProps {
    domain?: FrameworkDomain
    className?: string
}
const AuditResultSideBar: React.FC<AuditResultProps> = ({domain, className}) => {    

    const auditScore = useScoreCalculation();
    const {appState} = useContext(AppContext)

    //use either the provided domain or the current selected one
    const domainScoreKey = domain ? getMatchingDomain(domain) : getMatchingDomain(appState.currentFrameworkDomain)

    const achievedScore = auditScore.achievedScore[domainScoreKey]
    const totalScorePossible = auditScore.possibleTotalScore[domainScoreKey]
    const delta = totalScorePossible - achievedScore

    return <aside className="auditResultSideBar hidden lg:block">
            <DonutChart data={[achievedScore, delta]} />
        </aside>
}
 
export default AuditResultSideBar;