import { useState } from 'react';
import AppContext, { defaultAppState } from './state/AppContext';
import DomainNavigation from './questionaire/form/domainNavigation.component';
import DebugInfo from './questionaire/debugInfo.component';
import Footer from './footer/footer.component';
import ScrollToTop from './footer/scrollToTop.component';
import FrameworkQuestionaire from './questionaire/form/frameworkQuestionaire.component';
import AuthGuard from './auth/AuthGuard';

function App() {
  const [appState, setAppState] = useState(defaultAppState)
  return (
    <AppContext.Provider value={{appState, setAppState}}>
      <AuthGuard>
      <div className="">
      <img className="p3logo" src="logo_blue.svg" width="400px" />
        <header className="text-center">
          <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
            <span>Cloud Assessment Framework </span>
            <span className="text-xs text-gray-400 ml-1">
            v {appState.mainVersion}
          </span>
          </h1>

          <DomainNavigation/>
        </header>
        <FrameworkQuestionaire />
      </div>
      <DebugInfo/>
      <ScrollToTop/>
    </AuthGuard>
    <Footer/> {/**footer must always be present due to TMG in germany, even if the user is not authenticated */}
    </AppContext.Provider>
  );
}

export default App;
