import Question from "./Question";

const q1: Question = {
    title: {
        DE: "Wie überwachen und interpretieren Sie die erfassten Daten, um sich über den Anwendungszustand zu informieren?",
        EN: "How do you monitor and interpret the collected data to inform yourself about the application state?"
    },
    answers: [{
        text: {
            DE: "Anwendungsereignisse werden protokolliert.",
            EN: "Application events are logged."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Log-Dateien und Kennzahlen aus Cloud Provider Ressourcen werden mit einer Protokollaggregationstechnologie wie Azure Log Analytics, Loki oder Splunk erfasst.",
            EN: "Log files and metrics from cloud provider resources are collected using log aggregation technologies such as Azure Log Analytics, Loki, or Splunk."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es sind keine Lücken in der Anwendungsbeobachtung bekannt, die übersehene Vorfälle und/oder Fehlalarme zur Folge hatten.",
            EN: "No gaps in application monitoring are known that have led to missed incidents and/or false alarms."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Customer Experience wird durch entsprechende Metriken quantifiziert, erfasst und interpretiert (z. B. Google Lighthouse, Application Insights).",
            EN: "Customer experience is quantified, recorded, and interpreted using relevant metrics (e.g., Google Lighthouse, Application Insights)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Frontend Anwendungen nutzen Tools und Metriken, um Rückschlüsse auf die Customer Experience zu schließen.",
            EN: "Frontend applications use tools and metrics to draw conclusions about customer experience."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für kritische Ressourcen sind erweiterte Monitoring-Funktionen im Einsatz.",
            EN: "Advanced monitoring features are in place for critical resources."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Fehlerlogs werden regelmäßig geprüft, um Muster zu erkennen, die auf tiefer liegende Probleme hinweisen könnten.",
            EN: "Error logs are regularly reviewed to identify patterns that may indicate deeper issues."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für kritische Fehler ist ein Benachrichtigungssystem implementiert, das sofortige Alerts an das Entwicklungsteam sendet.",
            EN: "A notification system is implemented for critical errors, sending immediate alerts to the development team."
        },
        value: 0,
        weight: 1
    }],
    domain: "Operationale Exzellenz",
    id: "O01"
};

const q2: Question = {
    title: {
        DE: "Wie stellen Sie sicher, dass effizient auf Serviceanfragen, Krisen und Notfälle reagiert wird?",
        EN: "How do you ensure efficient responses to service requests, crises, and emergencies?"
    },
    answers: [{
        text: {
            DE: "Ein Incident Management- und Response-Plan ist aktiv und wird umgesetzt.",
            EN: "An incident management and response plan is active and implemented."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Workload-Daten werden über Dashboards (z.B. AppInsights, Grafana) visualisiert.",
            EN: "Workload data is visualized via dashboards (e.g., AppInsights, Grafana)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Erkennung von Fehlern und Katastrophen erfolgt durch ein integriertes Überwachungssystem, das frühzeitige Warnungen ermöglicht und Alerts aussendet.",
            EN: "Error and disaster detection is performed by an integrated monitoring system that enables early warnings and sends alerts."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Schlüsselszenarien für den Workload sind klar definiert und wurden im Hinblick auf operative Ziele und nicht-funktionale Anforderungen gründlich analysiert und mit entsprechenden Risikostrategien versehen.",
            EN: "Key scenarios for the workload are clearly defined and have been thoroughly analyzed in terms of operational goals and non-functional requirements, with appropriate risk strategies applied."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es ist in klar definierten Zeiträumen Servicepersonal aktiv, um eine schnelle Incidence Response zu gewährleisten.",
            EN: "Service personnel are active during clearly defined periods to ensure a quick incident response."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es sind mindestens 3 Service Level definiert, Personal zugewiesen und die Arbeitsteilung zwischen den Stufen klar dokumentiert.",
            EN: "At least 3 service levels are defined, personnel are assigned, and the division of labor between levels is clearly documented."
        },
        value: 0,
        weight: 1
    }],
    domain: "Operationale Exzellenz",
    id: "O02"
};

const q3: Question = {
    title: {
        DE: "Welche Best Practices werden befolgt, um hohe operationale Exzellenz sicherzustellen?",
        EN: "What best practices are followed to ensure high operational excellence?"
    },
    answers: [{
        text: {
            DE: "Manuelle Tätigkeiten mit hohem Aufwand werden regelmäßig erkannt, berichtet und nach und nach automatisiert.",
            EN: "Manual tasks with high effort are regularly identified, reported, and gradually automated."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Linting-Tools in Entwicklungsprozessen eingesetzt, um die Codequalität zu verbessern und Stilrichtlinien einzuhalten.",
            EN: "Linting tools are used in development processes to improve code quality and adhere to style guidelines."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Code Pushes sind nur mit erfolgreichem Linting möglich. Das Linting wird automatisch ausgelöst.",
            EN: "Code pushes are only possible with successful linting. Linting is triggered automatically."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Software Tests werden durch CI/CD-Pipelines automatisiert, um eine durchgehende Qualitätssicherung zu gewährleisten. Bei Fehlschlag der Tests kann kein weiterer Schritt erfolgen.",
            EN: "Software tests are automated through CI/CD pipelines to ensure continuous quality assurance. No further steps can occur if tests fail."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Skalierungen von Workloads erfolgen vollautomatisch anhand von Metriken oder durch Cloud Plattform, Kubernetes o.ä. voll automatisiert.",
            EN: "Scaling of workloads is fully automated based on metrics or via cloud platforms, Kubernetes, etc."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Codereviews sind ein fester Bestandteil der Entwicklungspraxis, um die Codeintegrität zu fördern.",
            EN: "Code reviews are an integral part of development practice to promote code integrity."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Versionierungstools (Git) werden eingesetzt, um Änderungen nachzuverfolgen und die Zusammenarbeit im Team zu unterstützen.",
            EN: "Versioning tools (Git) are used to track changes and support team collaboration."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Main Branches werden durch Schutzmechanismen (z.B. Policies) abgesichert, um die Stabilität des Codes zu gewährleisten.",
            EN: "Main branches are secured by protection mechanisms (e.g., policies) to ensure code stability."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Rollen und Verantwortlichkeiten innerhalb des Teams sind klar definiert und dokumentiert, um Transparenz und Rechenschaft zu fördern.",
            EN: "Roles and responsibilities within the team are clearly defined and documented to promote transparency and accountability."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Ein Ticketing System ist im Einsatz, zum Beispiel Azure Devops oder Gitlab. Arbeitsprozesse werden immer dokumentiert.",
            EN: "A ticketing system is in use, such as Azure DevOps or GitLab. Work processes are always documented."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es gibt optimierte und abgestimmte Vorlagen sowie Mindeststandards für Arbeitsprodukte (z. B. Tickets).",
            EN: "There are optimized and coordinated templates and minimum standards for work products (e.g., tickets)."
        },
        value: 0,
        weight: 1
    }],
    domain: "Operationale Exzellenz",
    id: "O03"
};

const q4: Question = {
    title: {
        DE: "Wie steuern Sie die Konfiguration Ihres Workloads?",
        EN: "How do you manage the configuration of your workload?"
    },
    answers: [{
        text: {
            DE: "Die Anwendungskonfigurationsdaten werden in einem speziellen Verwaltungssystem wie Azure App Configuration oder Azure Key Vault gespeichert.",
            EN: "Application configuration data is stored in a dedicated management system like Azure App Configuration or Azure Key Vault."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Soft-Delete ist für unsere Schlüssel und Anmeldedaten (z.B. in Key Vaults und Key-Vault-Objekten gespeicherte Dinge) aktiviert.",
            EN: "Soft-delete is enabled for our keys and credentials (e.g., items stored in key vaults and key vault objects)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Konfigurationseinstellungen können geändert werden, ohne die Anwendung neu entwickeln oder erneut bereitstellen zu müssen.",
            EN: "Configuration settings can be changed without the need to redevelop or redeploy the application."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Passwörter und andere Geheimnisse werden in einem sicheren Speicher wie Azure Key Vault oder HashiCorp Vault verwaltet.",
            EN: "Passwords and other secrets are managed in a secure storage such as Azure Key Vault or HashiCorp Vault."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Anwendung nutzt verwaltete Cloud Provider Identitäten, um den Bedarf an selbst gespeicherten Zugangsdaten zu minimieren.",
            EN: "The application uses managed cloud provider identities to minimize the need for self-stored credentials."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Ablauftermine von SSL-Zertifikaten werden überwacht, Verfahren zur Erneuerung sind etabliert und automatisch.",
            EN: "SSL certificate expiration dates are monitored, and renewal procedures are established and automated."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Managed Services werden bevorzugt, um den administrativen Aufwand zu reduzieren und Effizienz zu steigern.",
            EN: "Managed services are preferred to reduce administrative effort and increase efficiency."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Patches werden zentralisiert und automatisiert eingespielt.",
            EN: "Patches are applied centrally and automatically."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Unsere Infrastruktur wird als Code definiert (z. B. Terraform), um konsistente und reproduzierbare Umgebungen zu schaffen.",
            EN: "Our infrastructure is defined as code (e.g., Terraform) to create consistent and reproducible environments."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Infrastruktur-Konfigurationen werden versioniert.",
            EN: "Infrastructure configurations are versioned."
        },
        value: 0,
        weight: 1
    }],
    domain: "Operationale Exzellenz",
    id: "O04"
};

const q5: Question = {
    title: {
        DE: "Welche Maßnahmen zwecks ständiger Verbesserung sind im Einsatz?",
        EN: "What measures are in place for continuous improvement?"
    },
    answers: [{
        text: {
            DE: "Es sind klar definierte Entwicklungspläne und Ziele für die einzelnen Mitarbeiter definiert.",
            EN: "Clearly defined development plans and goals for individual employees are in place."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Retrospektive Reviews mit dem Ziel, Verbesserungspotenziale zu identifizieren, z. B. Sprintreviews, werden regelmäßig durchgeführt.",
            EN: "Retrospective reviews aimed at identifying improvement potentials, such as sprint reviews, are conducted regularly."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es wurden konkrete Aktionen umgesetzt, um Verbesserungsvorschläge durch Projektbeteiligte aktiv zu fördern.",
            EN: "Specific actions have been implemented to actively encourage suggestions for improvement from project participants."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Verbesserungsmöglichkeiten werden gesammelt, dokumentiert und regelmäßig evaluiert.",
            EN: "Improvement opportunities are collected, documented, and regularly evaluated."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Verbesserungen haben reservierte Kapazitäten im Rahmen der regelmäßigen Projektplanung, z. B. als reservierte Zeitslots in Sprints.",
            EN: "Improvements have reserved capacities within regular project planning, e.g., as reserved time slots in sprints."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden proaktiv neue Funktionen und Möglichkeiten der unserem Workload zugrunde liegenden Dienste gesichtet und evaluiert.",
            EN: "New features and capabilities of the services underlying our workload are proactively reviewed and evaluated."
        },
        value: 0,
        weight: 1
    }],
    domain: "Operationale Exzellenz",
    id: "O05"
};

const q6: Question = {
    title: {
        DE: "Wie ermöglichen Sie Wissenstransfer und Wachstum?",
        EN: "How do you facilitate knowledge transfer and growth?"
    },
    answers: [{
        text: {
            DE: "Schulungen und Weiterbildungen werden regelmäßig in Einklang mit Entwicklungszielen und Arbeitsrollen der Mitarbeiter durchgeführt.",
            EN: "Training and further education are regularly conducted in line with employees' development goals and job roles."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es ist ein Mechanismus (z. B. Workshops) etabliert, um über aktuellste technische Fortschritte, Änderungen und Trends zu informieren.",
            EN: "A mechanism (e.g., workshops) is established to inform about the latest technical advances, changes, and trends."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es liegt eine Wissensdatenbank vor (z. B. Wiki), die leicht zugänglich ist.",
            EN: "There is a knowledge base (e.g., wiki) that is easily accessible."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Wissensdatenbank wird regelmäßig gepflegt und überwacht.",
            EN: "The knowledge base is regularly maintained and monitored."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Aufgabenverteilung wird so zusammengestellt, dass Mitarbeiter sowohl eine Expertendomäne als auch zunehmendes ganzheitliches Kompetenzen aufbauen, z. B. durch Aufgabenrotation.",
            EN: "Task distribution is structured to allow employees to build both expertise in a domain and broader competencies, e.g., through task rotation."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Auch bei Ausfall von Mitarbeitern, z. B. durch Urlaub oder Krankheit, wird eine hinreichende Wissensbasis und Anleitungen gewährleistet, um den reibungslosen Arbeitsablauf nicht zu gefährden.",
            EN: "Even in the event of employee absences, e.g., due to vacation or illness, an adequate knowledge base and guidelines are ensured to maintain smooth workflows."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Weiterbildungen werden in der Gesamtplanung des Projekts als normaler Bestandteil fest einberechnet.",
            EN: "Training is factored into the overall project planning as a regular component."
        },
        value: 0,
        weight: 1
    }],
    domain: "Operationale Exzellenz",
    id: "L06"
};

const operationalExcellenceQuestions: Question[] = [q1, q2, q3, q4, q5, q6];

export default operationalExcellenceQuestions;
