import { useContext, useEffect, useState } from "react"
import Question from "../questions/Question"
import AppContext from "../../state/AppContext"
import labels from "../../labels/labels"

interface QuestionNoteFieldProps {
    question: Question
}

const QuestionNoteField: React.FC<QuestionNoteFieldProps> = ({question}) => {

    //TODO patch visuals if note is too long
    
    const [note, setNote] = useState<string>(question.note || '') //TODO talk to store instead
    const appContext = useContext(AppContext)
    const {language} = appContext.appState
    const DEBOUNCE_TIME = 700

    const saveNote = () => {
        appContext.setAppState(previousState => {
            const newState = {...previousState}
            newState.questions.find(qst => qst.title === question.title)!.note = note
            return newState
        })
    }

    //trigger the saveNote everytime the note changed, but with a certain debounce since we don't want to perform a save after every new letter
    useEffect(() => {
        const handler = setTimeout(() => {
            saveNote()
        }, DEBOUNCE_TIME)

        return () => {
            clearTimeout(handler)
        }
    }, [note])

    return <div className="question--note">
    <label htmlFor="note" className="block text-sm font-medium text-gray-700">
        {labels.note[language]}:
    </label>
    <textarea
        id="note"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        className="textarea"
    />
</div>

}


export default QuestionNoteField