import { useContext } from "react";
import AppContext from "../../state/AppContext";
import FrameworkDomain from "../FrameworkDomain";

const useScoreCalculation: () => AuditScore = () => {

    const appContext = useContext(AppContext)
    const {questions} = appContext.appState

    const MAX_SCORE_PER_ANSWER = 5

    const result: AuditScore = {
        hasErrors: false,
        achievedScore: {
            security: 0,
            reliability: 0,
            operationalExcellence: 0,
            performance: 0,
            costEfficiency: 0,
            totalScore: 0
        },
        possibleTotalScore: {
            security: 0,
            reliability: 0,
            operationalExcellence: 0,
            performance: 0,
            costEfficiency: 0,
            totalScore: 0
        }
    }

    questions.forEach(question => {
        if(!question.ignored){
            const matchingDomainKey = getMatchingDomain(question.domain) // must be converted from german domain name to english attribute key for access
            question.answers.forEach(answer => {

                const weight = answer.weight ?? 1 //1 is always the default weight. A higher weight means that this answer has additional priority in calculation

                result.achievedScore.totalScore += answer.value * weight
                result.achievedScore[matchingDomainKey] += answer.value * weight

                result.possibleTotalScore.totalScore +=  MAX_SCORE_PER_ANSWER * weight
                result.possibleTotalScore[matchingDomainKey] +=  MAX_SCORE_PER_ANSWER * weight
            })
        }
    })
    return result;
}

export const getMatchingDomain = (domain: FrameworkDomain) => {
    switch(domain){
        case 'Kostenoptimierung':
            return 'costEfficiency'
        case 'Leistungseffizienz':
            return 'performance'
        case 'Operationale Exzellenz':
            return 'operationalExcellence'
        case 'Sicherheit':
            return 'security'
        case 'Zuverlässigkeit':
            return 'reliability'
        default:
            throw new Error(`invalid question domain detected: ${domain}`)
    }
}

export interface AuditScore {
    hasErrors: boolean,
    achievedScore: {
        security: number,
        reliability: number,
        operationalExcellence: number,
        performance: number,
        totalScore: number,
        costEfficiency: number
    },
    possibleTotalScore: {
        security: number,
        reliability: number,
        operationalExcellence: number,
        performance: number,
        totalScore: number,
        costEfficiency: number
    }
}
 
export default useScoreCalculation;