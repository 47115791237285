import Question from "./Question";
import costOptimizationQuestions from "./costOptimizationQuestions";
import operationalExcellenceQuestions from "./operationalExcellenceQuestions";
import performanceQuestions from "./performanceQuestions";
import reliabilityQuestions from "./reliabilityQuestions";
import securityQuestions from "./securityQuestions";



const questions: Question[] = [
    ...reliabilityQuestions, 
    ...securityQuestions, 
    ...costOptimizationQuestions, 
    ...operationalExcellenceQuestions, 
    ...performanceQuestions]



export default questions