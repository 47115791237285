import { useContext } from "react";
import labels from "../../labels/labels";
import FrameworkDomain from "../FrameworkDomain";
import { AuditScore, getMatchingDomain } from "../results/useScoreCalculation";
import AppContext from "../../state/AppContext";

interface SummarySectionProps {
    domain: FrameworkDomain
    auditScore: AuditScore
}


const SummarySection: React.FC<SummarySectionProps> = ({ domain, auditScore }) => {

  const language = useContext(AppContext).appState.language

    const possibleScore = auditScore.possibleTotalScore[getMatchingDomain(domain)]
    const achievedScore = auditScore.achievedScore[getMatchingDomain(domain)]
    const procentualScore: number = ((achievedScore / possibleScore)*100)
    const procentualScoreAsString = procentualScore.toFixed(0)

    const displayedColor = () => {
        switch (true) {
          case procentualScore > 70:
            return 'text-green-500';
          case procentualScore > 50:
            return 'text-yellow-500';
          case procentualScore > 25:
            return 'text-orange-500';
          default:
            return 'text-red-500';
        }
      };

  return (
    <div className="result--section">
      <h3 className={`text-4xl ${displayedColor()} font-bold mb-2`}>{procentualScoreAsString}%</h3>
      <h4 className="text-xl font-semibold">
      {Object.values(labels.navigation).find(navPoint => navPoint["DE"] === domain)![language]} {/**TODO we must make the domain fully english for this to work properly. This is just a workaround */}
        </h4>
    </div>
  );
};

export default SummarySection