import Question from "./Question";

const q1: Question = {
    title: {
        DE: "Welche Zuverlässigkeitsziele und Kennzahlen haben Sie für Ihre Anwendung definiert?",
        EN: "What reliability goals and metrics have you defined for your application?"
    },
    answers: [{
        text: {
            DE: "Wiederherstellungsziele, um zu bestimmen, wie lange der Workload maximal ausfallen darf (Recovery Time Objective) und wie viele Daten während eines Notfalls verloren gehen dürfen (Recovery Point Objective).",
            EN: "Recovery objectives to determine how long the workload can be down (Recovery Time Objective) and how much data can be lost during an emergency (Recovery Point Objective)."
        },
        value: 0,
        tooltip: "Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet. Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet.",
        weight: 1
    }, {
        text: {
            DE: 'Verfügbarkeitsziele wie Service Level Argeements (SLAs) und Service Level Objectives (SLOs).',
            EN: 'Availability objectives such as Service Level Agreements (SLAs) and Service Level Objectives (SLOs).'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Verfügbarkeitskennzahlen zum Messen und Überwachen der Verfügbarkeit, z.B. Mean Time to Recover (MTTR) und Mean Time Between Failure (MTBF).",
            EN: "Availability metrics to measure and monitor availability, e.g., Mean Time to Recover (MTTR) and Mean Time Between Failure (MTBF)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Zusammengefasstes SLA für den Workload, abgeleitet aus den einzelnen SLAs für alle eingesetzten Ressourcen.",
            EN: "Consolidated SLA for the workload, derived from the individual SLAs for all deployed resources."
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z01"
};

const q2: Question = {
    title: {
        DE: "Wie haben Sie sichergestellt, dass Ihre Anwendungsarchitektur gegenüber Ausfällen resilient ist?",
        EN: "How have you ensured that your application architecture is resilient to failures?"
    },
    answers: [{
        text: {
            DE: "Es besteht eine Redundanz der Anwendung in einer Zweitregion, um regionalen Ausfällen entgegenzuwirken.",
            EN: "The application is redundant in a secondary region to counter regional failures."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Es besteht eine Redundanz der einzelnen Anwendungen, um Schäden durch Einzelausfälle (z. B. durch kritische Fehler in der Anwendung) einzudämmen.',
            EN: 'Individual applications are redundant to contain damage from individual failures (e.g., critical errors in the application).'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Die Anwendung ist in mehreren Verfügbarkeitszonen innerhalb einer Region bereitgestellt.',
            EN: 'The application is deployed in multiple availability zones within a region.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Es wurde eine Fehlermodusanalyse (FMA) zur Identifizierung von Fehlerquellen und Ausfallmodi durchgeführt.',
            EN: 'A Failure Mode Analysis (FMA) has been conducted to identify error sources and failure modes.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Eine Failover Policy ist eingerichtet, um bei Ausfällen vollautomatisch auf ein Ersatzsystem umzuleiten.',
            EN: 'A failover policy is set up to automatically redirect to a backup system in the event of a failure.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Dienste, die innerhalb des Workloads verwendet werden, sind wenn möglich mit einer High Availability Konfiguration versehen.',
            EN: 'Services used within the workload are configured with high availability whenever possible.'
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z02"
};

const q3: Question = {
    title: {
        DE: "Wie handhaben Sie die Notfallwiederherstellungen?",
        EN: "How do you handle disaster recoveries?"
    },
    answers: [{
        text: {
            DE: "Szenarien für das Eintreten einer Katastrophe sind modelliert und darauf basierend der Plan zur Notfallwiederherstellung.",
            EN: "Scenarios for disaster occurrence are modeled, and the disaster recovery plan is based on them."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Es liegt ein Desaster Recovery-Plan in der Dokumentation des Workloads vor, welcher Fehlermodi und Strategien zur Vermeidung von Ausfällen umfasst.',
            EN: 'A disaster recovery plan is documented for the workload, including failure modes and strategies to prevent outages.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Rollen und Verantwortlichkeiten innerhalb des Workload-Teams sind definiert. Externe Ansprechpartner, Kontaktdaten und relevante Rollen für solche Fälle sind ebenfalls dokumentiert und leicht zugänglich.',
            EN: 'Roles and responsibilities within the workload team are defined. External contacts, contact details, and relevant roles for such cases are also documented and easily accessible.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Eskalationspfade, die das Workload-Team befolgen muss, um die Wiederherstellung allen Beteiligten mitzuteilen, sind definiert.',
            EN: 'Escalation paths that the workload team must follow to communicate recovery to all stakeholders are defined.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Wiederherstellungsprozeduren nach Failovers sind definiert und bekannt.',
            EN: 'Recovery procedures following failovers are defined and known.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Es existieren dedizierte End-to-End Bereitstellungen (z.B. via Pipelines), welche eine Wiederherstellung des Systemzustands ohne größere Änderungen durchführen können.',
            EN: 'Dedicated end-to-end deployments (e.g., via pipelines) exist that can restore the system state without major changes.'
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z03"
};

const q4: Question = {
    title: {
        DE: "Welche Maßnahmen sichern die erforderte Zuverlässigkeit auf Anwendungsebene?",
        EN: "What measures ensure the required reliability at the application level?"
    },
    answers: [{
        text: {
            DE: "Die genutzten technologischen Frameworks befinden sich mit Ihrer Version mindestens im Rahmen des Hersteller-Supports.",
            EN: "The used technological frameworks are at least within the support scope of their respective versions by the vendor."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Der Anwendungscode folgt defensive Code Design Best Practices, z. B. Null-Object Pattern, Immutability, Datenvalidierung.",
            EN: "The application code follows defensive code design best practices, e.g., Null-Object Pattern, Immutability, Data Validation."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Falls Microservices genutzt werden, wird das Risiko eines kaskadierenden Ausfalls durch ein Bulkhead Pattern verhindert.",
            EN: "If microservices are used, the risk of cascading failures is prevented by using a Bulkhead Pattern."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Das Risiko einer Überlastung durch fehlerhafte Aufrufe wurde mitigiert, z. B. durch Circuit Breaker.",
            EN: "The risk of overloading due to faulty calls has been mitigated, e.g., by using Circuit Breakers."
        },
        value: 0,
        tooltip: "Test Tooltip",
        weight: 1
    }, {
        text: {
            DE: 'Es ist eine standardisierte Strategie im Umgang mit Anwendungsfehlern aktiv, um die Funktionalität der Anwendung auch bei einzelnen Fehlern zu erhalten.',
            EN: 'A standardized strategy for handling application errors is in place to maintain application functionality even in case of individual errors.'
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z04"
};

const q5: Question = {
    title: {
        DE: "Welche Maßnahmen sichern die erforderte Zuverlässigkeit auf Datenebene?",
        EN: "What measures ensure the required reliability at the data level?"
    },
    answers: [{
        text: {
            DE: "Es werden automatisch regelmäßige Backups durchgeführt.",
            EN: "Regular backups are automatically performed."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es existieren separat gesicherte Notfall Backups.",
            EN: "There are separately secured emergency backups."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Backup Strategie wurde anhand der vordefinierten Zuverlässigkeits-Ziele (RTO, RPO) ausgerichtet und erfüllt diese.",
            EN: "The backup strategy is aligned with predefined reliability goals (RTO, RPO) and meets these standards."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Datenintegrität wird durch entsprechende Prüfungen innerhalb Ihrer Datenbanksysteme sichergestellt.",
            EN: "Data integrity is ensured through appropriate checks within your database systems."
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z05"
};

const q6: Question = {
    title: {
        DE: "Welche Maßnahmen sichern die erforderte Zuverlässigkeit auf Netzwerkebene?",
        EN: "What measures ensure the required reliability at the network level?"
    },
    answers: [{
        text: {
            DE: "Redundante Netzwerkpfade und doppelte Hardwarekomponenten sind eingerichtet.",
            EN: "Redundant network paths and duplicate hardware components are in place."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Load Balancer verteilen den Datenverkehr gleichmäßig, um Überlastungen zu vermeiden.",
            EN: "Load balancers distribute traffic evenly to avoid overloads."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Ihr Netzwerkdesign ermöglicht durch automatische Failover den Betrieb auch bei Teilausfällen.",
            EN: "Your network design allows operation even during partial failures through automatic failover."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Sie setzen Quality of Service Richtlinien ein, um kritischen Verkehr zu priorisieren und Leistungsstandards zu garantieren.",
            EN: "Quality of Service (QoS) policies are used to prioritize critical traffic and ensure performance standards."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es sind DDoS-Schutzmaßnahmen getroffen worden.",
            EN: "DDoS protection measures have been implemented."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Durch kontinuierliche Netzwerküberwachung identifizieren und beheben Sie proaktiv Probleme.",
            EN: "Continuous network monitoring proactively identifies and resolves issues."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Sie aktualisieren Ihre Netzwerkgeräte und Software durch regelmäßige Updates.",
            EN: "You keep your network devices and software up-to-date through regular updates."
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z06"
};

const q7: Question = {
    title: {
        DE: "Wie testen Sie Ihre Anwendung, um sicherzustellen, dass sie fehlertolerant ist?",
        EN: "How do you test your application to ensure it is fault-tolerant?"
    },
    answers: [{
        text: {
            DE: "Unit-Tests isolieren und überprüfen einzelne Komponenten für korrekte Funktionalität und Robustheit.",
            EN: "Unit tests isolate and verify individual components for correct functionality and robustness."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Integrationstests evaluieren die Effizienz und Zuverlässigkeit der Interaktionen zwischen Modulen.",
            EN: "Integration tests evaluate the efficiency and reliability of interactions between modules."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Funktionale Tests bestätigen, dass die Anwendung die spezifizierten Anforderungen erfüllt.",
            EN: "Functional tests confirm that the application meets specified requirements."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Last- und Stresstests prüfen das Verhalten der Anwendung unter extremen Bedingungen.",
            EN: "Load and stress tests check the application's behavior under extreme conditions."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Durch Chaos Engineering werden Ausfälle simuliert, um die Widerstandsfähigkeit des Workloads zu testen.",
            EN: "Chaos engineering simulates failures to test the workload's resilience."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "End-to-End-Tests validieren die Anwendung in einer Umgebung, die der Produktionsumgebung nachempfunden ist.",
            EN: "End-to-end tests validate the application in an environment that mirrors the production environment."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Ausgewählte QA-Experten entwickeln Teststrategien und führen manuelle Tests durch, um die Anwendungsqualität sicherzustellen.",
            EN: "Selected QA experts develop test strategies and conduct manual tests to ensure application quality."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Failover-Tests überprüfen die Fähigkeit der Anwendung, bei einem Ausfall nahtlos auf ein Backup-System umzuschalten.",
            EN: "Failover tests check the application's ability to seamlessly switch to a backup system during a failure."
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z07"
};

const q8: Question = {
    title: {
        DE: "Wie handhabt Ihre Anwendungslogik Ausnahmen und Fehler?",
        EN: "How does your application logic handle exceptions and errors?"
    },
    answers: [{
        text: {
            DE: "Fehlermeldungen sind klar, informativ und benutzerfreundlich, um Endnutzern das Verständnis und die Handhabung von Problemen zu erleichtern.",
            EN: "Error messages are clear, informative, and user-friendly to help end-users understand and handle issues."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Anwendungslogik ist so gestaltet, dass sie das Kaskadieren von Ausnahmen vermeidet und stattdessen gezielte Maßnahmen ergreift.",
            EN: "The application logic is designed to avoid cascading exceptions and instead takes targeted actions."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für kritische Vorgänge werden Transaktionen oder ähnliche Mechanismen genutzt, um Datenintegrität auch im Fehlerfall sicherzustellen.",
            EN: "Transactions or similar mechanisms are used for critical operations to ensure data integrity even in case of errors."
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z08"
};

const q9: Question = {
    title: {
        DE: "Wie überwachen und messen Sie den Anwendungszustand?",
        EN: "How do you monitor and measure the application state?"
    },
    answers: [{
        text: {
            DE: "Alle Komponenten werden überwacht und Anwendungstelemetriedaten erfasst.",
            EN: "All components are monitored and application telemetry data is collected."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Wichtige Kennzahlen, Schwellenwerte und Indikatoren werden definiert und erfasst.",
            EN: "Key metrics, thresholds, and indicators are defined and collected."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Auf der Grundlage von Leistungs-, Verfügbarkeits- und Wiederherstellungszielen wurde ein Zustandsmodell definiert, das durch ein Überwachungs-Dashboard (z. B. Grafana, Azure Application Insights) und Warnmeldungen abgebildet wird.",
            EN: "A health model has been defined based on performance, availability, and recovery goals, represented by a monitoring dashboard (e.g., Grafana, Azure Application Insights) and alerts."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Anhand von Ereignissen in den Cloud Provider Ressource und Service Health Services werden beim Eintritt entsprechender Zustandsereignisse gewarnt.",
            EN: "Events in cloud provider resources and service health services trigger warnings when certain health events occur."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: 'Es existieren Alarmkonfigurationen, die beim Überschreiten der Ressourcenauslastung die Systemadministratoren alarmieren.',
            EN: 'Alarm configurations exist that notify system administrators when resource utilization exceeds predefined limits.'
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Anwendung wird mit semantischen Protokollen und Kennzahlen instrumentiert.",
            EN: "The application is instrumented with semantic logs and metrics."
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z09"
};

const q10: Question = {
    title: {
        DE: "Wie managen Sie die Zuverlässigkeit von abhängigen Diensten und Drittanbieter-Komponenten?",
        EN: "How do you manage the reliability of dependent services and third-party components?"
    },
    answers: [{
        text: {
            DE: "Abhängige Dienste und Drittanbieter-Komponenten werden basierend auf ihrer Zuverlässigkeit, Leistungsgeschichte und Sicherheitsstandards ausgewählt.",
            EN: "Dependent services and third-party components are selected based on their reliability, performance history, and security standards."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es bestehen SLAs mit allen kritischen Drittanbietern.",
            EN: "SLAs exist with all critical third-party providers."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Im Falle eines Ausfalls ist eine Failover Strategie eingerichtet.",
            EN: "A failover strategy is in place in the event of a failure."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Leistung und Verfügbarkeit abhängiger Dienste wird konstant überwacht. Abnormalitäten lösen entsprechende Alarme aus.",
            EN: "The performance and availability of dependent services are continuously monitored. Abnormalities trigger corresponding alarms."
        },
        value: 0,
        weight: 1
    }],
    domain: "Zuverlässigkeit",
    id: "Z10"
};

const reliabilityQuestions: Question[] = [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10];

export default reliabilityQuestions;
