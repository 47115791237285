import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-100 text-gray-600 py-4 border-t border-gray-300 mt-8">
            <div className="container mx-auto text-center">
                <p className="text-sm">
                    © 2024 P3 Digital Services GmbH. Heilbronner Straße 86, 70191 Stuttgart. Sven Jankowski. Alle Rechte vorbehalten.
                </p>
                <p className="text-xs mt-2">
                    Dieser Inhalt dient nur zu Informationszwecken und stellt keine rechtliche Beratung dar.
                    Zu allen weiteren Fragen, wenden Sie sich an sven.jankowski@p3-group.com
                </p>
            </div>
        </footer>
    );
};

export default Footer;
