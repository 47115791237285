import { useContext } from "react"
import AppContext from "../../state/AppContext"
import AuditQuestionaire from "../auditQuestionaire.component"
import AuditResultSideBar from "../results/AuditResultSideBar.component"
import Summary from "./summary.component"

const FrameworkQuestionaire = () => {
  //very simple router. Since we have 2, maybe maximum 3 different pages, setting up a router is not needed

    const appContext = useContext(AppContext)
    if(appContext.appState.displaying === 'questions'){
    return  <section className="row md:justify-center lg:mx-[var(--spacing-xl)]">
      <AuditQuestionaire/>
      <AuditResultSideBar className="auditResultSideBar"/>
    </section>
  }

  return <Summary/>
}

export default FrameworkQuestionaire