import { useContext } from "react";
import AppContext from "../../state/AppContext";

const  SwitchLanguage: React.FC = () => {
    const appContext = useContext(AppContext)
    const {setAppState} = appContext
    const currentLanguage = appContext.appState.language

    const changeLanguage = () => {
        const newLanguage = currentLanguage === 'DE' ? 'EN' : 'DE' //we can keep it very simple since it's unlikely that we will need more than 2 languages
        setAppState(previousState => ({...previousState, language: newLanguage}))
    }
    return <button onClick={() => changeLanguage()}>{currentLanguage}</button>
}

export default SwitchLanguage;