import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Answer } from "../questions/Question";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import AppContext from "../../state/AppContext";
import labels from "../../labels/labels";

export interface AnswerProps {
  answer: Answer
  questionId: string
}

const AnswerFormField: React.FC<AnswerProps> = ({
  answer,
  questionId,
}) => {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

  const appContext = useContext(AppContext)
  const setAppState = appContext.setAppState
  const {language} = appContext.appState

  const toggleAdditionalInfo = () => {
    setShowAdditionalInfo((prev) => !prev);
  };

  const showAdditionalInfoIcon = !!answer.tooltip;

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.valueAsNumber)
    setAppState(previousState => {
      const newState = {...previousState}
      //find the value of this answer and update it
      newState.questions.find(q => q.id === questionId)!.answers.find(availableAnswer => availableAnswer.text === answer.text)!.value = e.target.valueAsNumber
      return newState;
    })
  };

  return (
    <div className="answer">
      <div key={answer.text[language]} className="answer--main">
        <span className="answer--text cursor-pointer" title={answer.tooltip ?? ""}>
          <span>
            {answer.text[language]}
          </span>
          {showAdditionalInfoIcon && (
            <span className="additional--info" onClick={() => toggleAdditionalInfo()}>
              <FontAwesomeIcon icon={faInfo} className="answer--info-icon" />
            </span>
          )}
        </span>
      </div>
      {/* Range-Input von 0 bis 5 */}
      <div className="range-input-container">
        <input
          type="range"
          id="range-input"
          min="0"
          max="5"
          value={answer.value}
          onChange={handleRangeChange}
          className="mt-1 w-full"
        />
        <div className="range-labels flex justify-between text-xs mt-1">
          <span>0 - {labels.rangeSlider.doesNotApply[language]}</span>
          <span>5 - {labels.rangeSlider.doesApply[language]}</span>
        </div>
      </div>
      {showAdditionalInfo && (
        <div className="answer--additionalInformation">{answer.tooltip}</div>
      )}
    </div>
  );
};

export default AnswerFormField;
