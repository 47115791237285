import { LogLevel } from "@azure/msal-browser";
import process from "process";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? '5ca8d5c9-30db-47f2-b4ce-e3e3bc394afa',
        authority: "https://login.microsoftonline.com/p3-holding.com",
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?? 'https://claudit.p3-group.com',
    },
};

console.log(msalConfig.auth.clientId)

export const loginRequest = {
    scopes: [ "openid", "profile"],
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    }
};