import { useContext } from "react";
import AppContext from "../state/AppContext";

const DebugInfo = () => {
    const appContext = useContext(AppContext)
    console.info(appContext)
    return <div>
        
    </div>
}
 
export default DebugInfo;