import Question from "./Question";

const q1: Question = {
    title: {
        DE: "Wie haben Sie Ihren Workload hinsichtlich Bedrohungen analysiert?",
        EN: "How have you analyzed your workload for threats?"
    },
    answers: [{
        text: {
            DE: "Bedrohungsmodellierungsprozesse sind implementiert. Identifizierte Bedrohungen werden gemäß ihrer Auswirkungen auf das Unternehmen eingestuft, Abhilfemaßnahmen zugeordnet und den Stakeholdern mitgeteilt.",
            EN: "Threat modeling processes are implemented. Identified threats are classified according to their impact on the business, mitigation measures are assigned, and stakeholders are informed."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Zeitpläne und Verfahren für die Abhilfemaßnahmen (Sicherheitsbehebungen) bei erkannten Bedrohungen sind festgelegt.",
            EN: "Schedules and procedures for mitigation measures (security fixes) for identified threats are established."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es sind Sicherheitsanforderungen schriftlich definiert.",
            EN: "Security requirements are defined in writing."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Sicherheitslage wurde anhand von Standard-Benchmarks (CIS Control Framework, MITRE Framework, usw.) bewertet.",
            EN: "The security posture has been assessed based on standard benchmarks (CIS Control Framework, MITRE Framework, etc.)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Geschäftskritische Workloads, deren Kompromittierung oder Ausfall sich negativ auf den Betrieb auswirken können, wurden identifiziert und klassifiziert.",
            EN: "Business-critical workloads whose compromise or failure could negatively impact operations have been identified and classified."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S01"
};

const q2: Question = {
    title: {
        DE: "Welche Compliance- und Governance-Aspekte haben Sie berücksichtigt?",
        EN: "What compliance and governance aspects have you considered?"
    },
    answers: [{
        text: {
            DE: "Anforderungen der Datenschutz-Grundverordnung (DSGVO) und anderer lokaler Datenschutzgesetze sind berücksichtigt, um den Schutz personenbezogener Daten sicherzustellen.",
            EN: "Requirements of the General Data Protection Regulation (GDPR) and other local data protection laws are considered to ensure the protection of personal data."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Branchenspezifische Standards (z.B. ISO 13485 in der Medizintechnik) berücksichtigt. Es ist mindestens ein Beauftragter hierfür definiert, ein Katalog an Maßnahmen ist verschriftlicht und umgesetzt.",
            EN: "Industry-specific standards (e.g., ISO 13485 in medical technology) are considered. At least one officer is defined for this purpose, and a catalog of measures is documented and implemented."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Allgemeinen Sicherheitsstandards und Frameworks wie (z.B. NIST Cybersecurity Framework) sind verschriftlicht und werden befolgt.",
            EN: "General security standards and frameworks (e.g., NIST Cybersecurity Framework) are documented and followed."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Interne Governance-Richtlinien bezüglich Zugriffskontrollen, Datenmanagement und Incident-Response wurden implementiert und werden regelmäßig überprüft.",
            EN: "Internal governance policies regarding access control, data management, and incident response have been implemented and are regularly reviewed."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Der Schutz von geistigem Eigentum und die Einhaltung von Lizenzbedingungen für Software und Drittanbieter-Komponenten wurden in die Planung einbezogen.",
            EN: "The protection of intellectual property and compliance with licensing terms for software and third-party components have been included in the planning."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Der Workload ist so konzipiert, dass er vollständig auditierbar ist und den Reporting-Anforderungen für interne und externe Audits genügt.",
            EN: "The workload is designed to be fully auditable and meets reporting requirements for internal and external audits."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es sind umfassende Risikomanagementprozesse etabliert, die regelmäßig aktualisiert werden, um neue und sich entwickelnde Risiken zu adressieren.",
            EN: "Comprehensive risk management processes are established and regularly updated to address new and evolving risks."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S02"
};

const q3: Question = {
    title: {
        DE: "Welche Sicherheitsorientierte Methoden und Tools haben Sie im Rahmen des Entwicklungszyklus implementiert?",
        EN: "What security-oriented methods and tools have you implemented during the development cycle?"
    },
    answers: [{
        text: {
            DE: "Wir führen regelmäßige Sicherheits-Code-Reviews durch, um Schwachstellen frühzeitig zu erkennen und zu beheben.",
            EN: "We conduct regular security code reviews to identify and fix vulnerabilities early."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Static Application Security Testing (SAST)-Tools genutzt, um Code auf Sicherheitslücken zu analysieren, bevor er in Produktion geht.",
            EN: "Static Application Security Testing (SAST) tools are used to analyze code for security vulnerabilities before it goes into production."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Dynamic Application Security Testing (DAST)-Tools eingesetzt, um den Workload in laufender Umgebung auf Schwachstellen zu testen.",
            EN: "Dynamic Application Security Testing (DAST) tools are used to test the workload for vulnerabilities in the running environment."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Externe Sicherheitsexperten führen Penetrationstests durch, um die Sicherheit des Workloads aus der Perspektive eines Angreifers zu bewerten.",
            EN: "External security experts conduct penetration tests to assess the workload's security from an attacker's perspective."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Sicherheitstests sind in Ihren Continuous Integration und Continuous Deployment (CI/CD)-Pipelines integriert, um kontinuierliche Sicherheitsüberprüfungen zu gewährleisten.",
            EN: "Security tests are integrated into your Continuous Integration and Continuous Deployment (CI/CD) pipelines to ensure continuous security checks."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Das Entwicklerteam wird regelmäßig in sicheren Codierpraktiken und aktuellsten Sicherheitstrends geschult. Es ist mindestens eine Person, die in Sicherheitsaspekten explizit und hinreichend geschult wurde für das Team zugänglich.",
            EN: "The development team is regularly trained in secure coding practices and the latest security trends. At least one person trained in security aspects is accessible to the team."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Tools zum Scannen von Abhängigkeiten genutzt (z. B. npm audit, dependency-track), um bekannte Sicherheitslücken in Bibliotheken und Paketen zu identifizieren.",
            EN: "Tools for scanning dependencies are used (e.g., npm audit, dependency-track) to identify known vulnerabilities in libraries and packages."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S03"
};

const q4: Question = {
    title: {
        DE: "Wie ist ihr Ansatz zur sicheren Entwicklung und Wartung von Software gestaltet?",
        EN: "How is your approach to secure software development and maintenance designed?"
    },
    answers: [{
        text: {
            DE: "Sichere Codestandards und Best Practices, die von anerkannten Organisationen wie OWASP bereitgestellt werden, werden strikt eingehalten.",
            EN: "Secure coding standards and best practices provided by recognized organizations like OWASP are strictly followed."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Ein systematisches Patch-Management ist etabliert, um bekannte Schwachstellen schnell zu adressieren.",
            EN: "A systematic patch management process is established to quickly address known vulnerabilities."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Umfassende, aktuelle Dokumentationen stellen sicher, dass sicherheitsrelevante Informationen innerhalb des Teams geteilt werden und zugänglich sind.",
            EN: "Comprehensive, up-to-date documentation ensures that security-relevant information is shared and accessible within the team."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S04"
};

const q5: Question = {
    title: {
        DE: "Wie wird Ihr Workload konfiguriert?",
        EN: "How is your workload configured?"
    },
    answers: [{
        text: {
            DE: "Automatisierte Tools wie Ansible, Chef oder Terraform sind im Einsatz, um konsistente und sichere Konfigurationen des Workloads zu gewährleisten.",
            EN: "Automated tools such as Ansible, Chef, or Terraform are used to ensure consistent and secure workload configurations."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Der Workload wird gemäß der vordefinierten Sicherheitsziele, Branchenstandards und Richtlinien etablierter Sicherheitsframeworks konfiguriert.",
            EN: "The workload is configured according to predefined security goals, industry standards, and established security framework guidelines."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Falls Kubernetes Cluster oder Service Fabric eingesetzt werden, werden diese regelmäßig mit passenden Benchmarking Tools auf ihre Sicherheit geprüft und Maßnahmen abgeleitet.",
            EN: "If Kubernetes clusters or Service Fabric are used, they are regularly tested for security using appropriate benchmarking tools, and measures are derived."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden cloud native SIEM-Systeme (z. B. Azure Sentinel, AWS Security Hub) zur Echtzeitüberwachung und -analyse von Sicherheitswarnungen und -ereignissen eingesetzt.",
            EN: "Cloud-native SIEM systems (e.g., Azure Sentinel, AWS Security Hub) are used for real-time monitoring and analysis of security alerts and events."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S05"
};

const q6: Question = {
    title: {
        DE: "Wie reagieren Sie auf Sicherheitsverletzungen? Welche Incident Response Maßnahmen sind etabliert?",
        EN: "How do you respond to security breaches? What incident response measures are in place?"
    },
    answers: [{
        text: {
            DE: "Ein spezialisiertes Incident Response Team wird sofort aktiviert, um auf Sicherheitsverletzungen zu reagieren.",
            EN: "A specialized incident response team is immediately activated to respond to security breaches."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Betroffene Parteien und Behörden werden gemäß gesetzlichen Vorgaben und internen Richtlinien informiert.",
            EN: "Affected parties and authorities are informed according to legal requirements and internal policies."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Ein klarer Kommunikationsplan wird aktiviert, um interne und externe Stakeholder angemessen zu informieren.",
            EN: "A clear communication plan is activated to appropriately inform internal and external stakeholders."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Eine detaillierte Analyse wird durchgeführt, um den Umfang der Verletzung zu verstehen und die Ursache zu identifizieren. Die Ergebnisse dieser Analysen werden dokumentiert.",
            EN: "A detailed analysis is conducted to understand the extent of the breach and identify the cause. The results of these analyses are documented."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Post Mortem Analysen sind etabliert, um aus Sicherheitsvorfällen zu lernen, Vorsorge Maßnahmen abzuleiten und zukünftige Risiken zu minimieren.",
            EN: "Post-mortem analyses are established to learn from security incidents, derive preventive measures, and minimize future risks."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Schulung und Bewusstseinsbildung: 'Das Bewusstsein und die Schulung der Mitarbeiter werden verstärkt, um die Sicherheitskultur zu stärken.'",
            EN: "Training and awareness: 'Awareness and training of employees are intensified to strengthen the security culture.'"
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S06"
};

const q7: Question = {
    title: {
        DE: "Wie haben Sie das Netzwerk und Verbindungen Ihres Workloads abgesichert?",
        EN: "How have you secured the network and connections of your workload?"
    },
    answers: [{
        text: {
            DE: "Strenge Firewall-Regeln blocken unautorisierte Zugriffe und blockieren ungewollten Datenverkehr.",
            EN: "Strict firewall rules block unauthorized access and unwanted traffic."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Durch Netzwerksegmentierung werden kritische Systeme isoliert und Angriffsflächen reduziert.",
            EN: "Network segmentation isolates critical systems and reduces attack surfaces."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Öffentliche und private Netzwerke werden klar getrennt.",
            EN: "Public and private networks are clearly separated."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für Remote-Zugriffe werden gesicherte VPN-Verbindungen, eine Jumpbox oder die angebotenen Backbone Systeme des Cloud Providers genutzt.",
            EN: "Secured VPN connections, a jump box, or the cloud provider's backbone systems are used for remote access."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Intrusion Detection und Prevention Systeme genutzt, um verdächtige Aktivitäten im Netzwerk zu erkennen und darauf zu reagieren.",
            EN: "Intrusion detection and prevention systems are used to detect and respond to suspicious network activities."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es sind DDoS-Schutzmaßnahmen eingerichtet, um unsere Systeme vor Überlastungsangriffen zu schützen.",
            EN: "DDoS protection measures are in place to protect our systems from overload attacks."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S07"
};

const q8: Question = {
    title: {
        DE: "Welche Verschlüsselungsmethoden setzen Sie ein?",
        EN: "What encryption methods do you use?"
    },
    answers: [{
        text: {
            DE: "Verschlüsselung bei der Datenübertragung mittels TLS.",
            EN: "Encryption during data transmission using TLS."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Verschlüsselung bei der Datenspeicherung (z.B. Azure Always Encrypted).",
            EN: "Encryption during data storage (e.g., Azure Always Encrypted)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für die Verschlüsselung werden ausschließlich starke und branchenweit anerkannte Algorithmen genutzt (z.B. AES-256).",
            EN: "Only strong and industry-recognized encryption algorithms (e.g., AES-256) are used."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Alle Webdienste erzwingen HTTPS.",
            EN: "All web services enforce HTTPS."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für besonders sensible Kommunikation wird Ende-zu-Ende-Verschlüsselung eingesetzt.",
            EN: "End-to-end encryption is used for particularly sensitive communication."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden regelmäßige Audits bezüglich Verschlüsselungspraktiken, Compliance mit Industriestandards und gesetzlichen Anforderungen durchgeführt.",
            EN: "Regular audits of encryption practices, compliance with industry standards, and legal requirements are conducted."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S08"
};

const q9: Question = {
    title: {
        DE: "Wie werden Schlüssel, Geheimnisse und Zertifikate geschützt?",
        EN: "How are keys, secrets, and certificates protected?"
    },
    answers: [{
        text: {
            DE: "Es werden zentrale Schlüsselmanagement Lösungen (z.B. Azure Key Vault oder AWS Key Management Service) genutzt, um kryptografische Schlüssel und Geheimnisse zentral zu verwalten und zu schützen.",
            EN: "Central key management solutions (e.g., Azure Key Vault or AWS Key Management Service) are used to centrally manage and protect cryptographic keys and secrets."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die automatische Rotation von Schlüsseln ist aktiviert, um das Risiko von Schlüsselkompromittierungen zu minimieren.",
            EN: "Automatic key rotation is enabled to minimize the risk of key compromises."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Strenge Zugriffskontrollen werden eingesetzt, um sicherzustellen, dass nur autorisiertes Personal Zugang zu Schlüsseln und Geheimnissen hat.",
            EN: "Strict access controls are used to ensure that only authorized personnel have access to keys and secrets."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Certificate Management Funktionalitäten des Cloud Providers für die Erstellung, Verwaltung und Automatisierung der Erneuerung von SSL/TLS-Zertifikaten genutzt.",
            EN: "Certificate management functionalities of the cloud provider are used to create, manage, and automate the renewal of SSL/TLS certificates."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S09"
};

const q10: Question = {
    title: {
        DE: "Welche Sicherheitskontrollen bestehen für den Zugriff auf die Infrastruktur des Public Cloud Anbieters?",
        EN: "What security controls are in place for access to the public cloud provider's infrastructure?"
    },
    answers: [{
        text: {
            DE: "Es besteht eine Multi-Faktor-Authentifizierung Pflicht für alle Benutzer, die auf die Cloud-Infrastruktur zugreifen.",
            EN: "Multi-factor authentication is required for all users accessing the cloud infrastructure."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es gibt Conditional Access Policies, um den Zugriff basierend auf Benutzerstandort, Gerätetyp und anderen Sicherheitsbedingungen zu steuern.",
            EN: "Conditional access policies are in place to control access based on user location, device type, and other security conditions."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden IAM-Richtlinien eingesetzt, um detaillierte Zugriffskontrollen auf Cloud-Ressourcen zu definieren und durchzusetzen.",
            EN: "IAM policies are used to define and enforce detailed access controls on cloud resources."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es wird Just in Time Access genutzt, um hochkritische Rechte zeitlich eingeschränkt und kontrolliert zu vergeben.",
            EN: "Just-in-time access is used to grant highly critical rights in a time-restricted and controlled manner."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Der Zugang zu Rechten im Umgang mit der Cloud wird regelmäßig und automatisch überprüft und widerrufen, sobald dieser nicht mehr notwendig ist.",
            EN: "Access rights for cloud interaction are regularly and automatically reviewed and revoked when no longer necessary."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Phishing-Simulationen und Schulungen werden eingesetzt, um das Bewusstsein und die Reaktionsfähigkeit der Mitarbeiter zu erhöhen und deren Zugangsdaten zu schützen.",
            EN: "Phishing simulations and training are used to increase employee awareness and responsiveness and protect their credentials."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S10"
};

const q11: Question = {
    title: {
        DE: "Wie verwalten Sie Authentifizierung und Authorisierung in Ihrer Anwendung?",
        EN: "How do you manage authentication and authorization in your application?"
    },
    answers: [{
        text: {
            DE: "Für die Authentifizierung und Autorisierung auf Anwendungsebene werden moderne Protokolle wie OAuth 2.0 zusammen mit OpenID Connect eingesetzt, um sichere Zugriffstokens für Benutzer zu generieren und deren Identität zu verifizieren.",
            EN: "Modern protocols like OAuth 2.0 and OpenID Connect are used for authentication and authorization at the application level to generate secure access tokens for users and verify their identity."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Benutzer werden in Rollen gruppiert, die spezifische Berechtigungen enthalten, um den Zugang zu Funktionen und Daten gemäß dem Prinzip des geringsten Privilegs zu steuern.",
            EN: "Users are grouped into roles that contain specific permissions to control access to functions and data according to the principle of least privilege."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "APIs sind durch Sicherheitsschichten wie API-Gateways geschützt, die Anfragen validieren und nur authentifizierten und autorisierten Verkehr durchlassen.",
            EN: "APIs are protected by security layers like API gateways that validate requests and only allow authenticated and authorized traffic."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Authentifizierungs- und Autorisierungsversuche werden protokolliert, um verdächtige Aktivitäten schnell zu identifizieren und darauf reagieren zu können.",
            EN: "Authentication and authorization attempts are logged to quickly identify suspicious activities and respond accordingly."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S11"
};

const q12: Question = {
    title: {
        DE: "Wie klassifizieren Sie sensible Daten und behandeln diese?",
        EN: "How do you classify sensitive data and handle it?"
    },
    answers: [{
        text: {
            DE: "Es besteht eine umfassende Datenklassifizierungspolitik, die verschiedene Sensibilitätsstufen definiert, um sensible Daten entsprechend ihrer Wichtigkeit und dem Risiko bei Offenlegung zu kategorisieren.",
            EN: "A comprehensive data classification policy defines various sensitivity levels to categorize sensitive data according to its importance and the risk of exposure."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Zugriff auf sensible Daten wird streng geregelt, wobei das Prinzip des geringsten Privilegs angewendet wird, um sicherzustellen, dass nur autorisiertes Personal Zugang hat.",
            EN: "Access to sensitive data is strictly regulated, applying the principle of least privilege to ensure that only authorized personnel have access."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es existieren Richtlinien für die Aufbewahrung und Löschung sensibler Daten, um sicherzustellen, dass Daten nicht länger als nötig aufbewahrt werden.",
            EN: "Policies exist for the retention and deletion of sensitive data to ensure that data is not kept longer than necessary."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Der Umgang mit sensiblen Daten richtet sich nach den relevanten Datenschutzgesetzen und -vorschriften, wie der DSGVO, und Branchenstandards.",
            EN: "The handling of sensitive data follows relevant data protection laws and regulations, such as GDPR, and industry standards."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Data Masking wird eingesetzt, um kritische Daten bestmöglich auch z. B. vor Administratoren zu verhüllen.",
            EN: "Data masking is used to best conceal critical data, e.g., from administrators."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Data Governance Tools des Cloud Providers genutzt, um regelmäßige Reports über unsere Datenstände zu erhalten und ungeschützte, sensible Daten zu entdecken.",
            EN: "Data governance tools from the cloud provider are used to receive regular reports on our data status and identify unprotected sensitive data."
        },
        value: 0,
        weight: 1
    }],
    domain: "Sicherheit",
    id: "S12"
};

const securityQuestions: Question[] = [q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12];

export default securityQuestions;
