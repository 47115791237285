import Question from "./Question";

const q1: Question = {
    title: {
        DE: "Wie modellieren Sie die Anwendungs-/Cloud-Kosten?",
        EN: "How do you model application/cloud costs?"
    },
    answers: [{
        text: {
            DE: "Die anfallenden Kosten sind bekannt und dokumentiert.",
            EN: "The incurred costs are known and documented."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Das Preismodell des Workloads ist bekannt.",
            EN: "The pricing model of the workload is known."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Kosten externer Abhängigkeiten und Dienstleistungen sind dokumentiert und optimiert.",
            EN: "Costs of external dependencies and services are documented and optimized."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Kostenfolge der einzelnen von der Anwendung verwendeten Cloud Provider - Dienste ist bekannt.",
            EN: "The cost impact of each cloud provider service used by the application is known."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Beim Anfall neuer Infrastruktur oder veränderter Anforderungen werden Kostenschätzungen durch die vom Cloud Provider gestellten Kostenschätzungstools eingeholt und geprüft.",
            EN: "Cost estimates are obtained and reviewed using cloud provider cost estimation tools when new infrastructure or changing requirements arise."
        },
        value: 0,
        weight: 1
    }],
    domain: "Kostenoptimierung",
    id: "Z01"
};

const q2: Question = {
    title: {
        DE: "Wie überwachen Sie die Kosten bei diesem Workload?",
        EN: "How do you monitor the costs of this workload?"
    },
    answers: [{
        text: {
            DE: "Es gibt klar definierte Budgets und Kostengrenzen für das Gesamtsystem.",
            EN: "There are clearly defined budgets and cost limits for the entire system."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es gibt definierte Budgets für Teilsysteme des Workloads.",
            EN: "Defined budgets exist for subsystems of the workload."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Bei prognostiziertem Überschreiten der Kostenschwellen werden entsprechende Alarme ausgelöst.",
            EN: "Alerts are triggered when cost thresholds are projected to be exceeded."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es gibt mindestens eine dedizierte verantwortliche Person für die anfallenden Kosten.",
            EN: "There is at least one dedicated person responsible for the incurred costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Mithilfe von Cloud-Management-Tools wie AWS Cost Explorer, Azure Advisor oder Azure Cost Management werden Kosten in Echtzeit überwacht, um unerwartete Ausgaben sofort zu erkennen.",
            EN: "Cloud management tools such as AWS Cost Explorer, Azure Advisor, or Azure Cost Management monitor costs in real-time to immediately detect unexpected expenses."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Durch Subscriptions, Ressourcengruppen und Tagging von Ressourcen können Kosten einzelnen Teams, Projekten oder Workloads zugeordnet werden, um Transparenz zu schaffen und präzise Analysen zu erlauben.",
            EN: "Subscriptions, resource groups, and tagging of resources allow costs to be assigned to individual teams, projects, or workloads, enabling transparency and precise analysis."
        },
        value: 0,
        weight: 1
    }],
    domain: "Kostenoptimierung",
    id: "K02"
};

const q3: Question = {
    title: {
        DE: "Wie unterstützen Ihre DevOps-Praktiken und Arbeitsprozesse Ihre Kosteneffizienz?",
        EN: "How do your DevOps practices and workflows support cost efficiency?"
    },
    answers: [{
        text: {
            DE: "DevOps-Automatisierungsprozesse minimieren manuelle Aufwände und reduzieren dadurch die Betriebskosten.",
            EN: "DevOps automation processes minimize manual efforts and thereby reduce operational costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "CI/CD Pipelines reduzieren die Zeit und Ressourcen, die für die Entwicklung und Bereitstellung von Software benötigt werden.",
            EN: "CI/CD pipelines reduce the time and resources required for software development and deployment."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die eingesetzten DevOps-Tools integrieren Kostenüberwachung, damit Teams die Auswirkungen ihrer Änderungen auf die Kosten verstehen und verwalten können.",
            EN: "The DevOps tools in use integrate cost monitoring, enabling teams to understand and manage the cost impact of their changes."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es sind Lebenszyklusmanagement-Prozesse im Einsatz, z.B. durch Revisionen, um Ressourcen effektiv zu nutzen und veraltete oder unnötige Dienste zu eliminieren.",
            EN: "Lifecycle management processes, such as reviews, are in place to effectively utilize resources and eliminate outdated or unnecessary services."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Regelmäßige Schulungen erhöhen das Kostenbewusstsein im gesamten Team und fördern kosteneffizientes Verhalten bei der Nutzung von Cloud-Ressourcen.",
            EN: "Regular training increases cost awareness throughout the team and promotes cost-efficient behavior when using cloud resources."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Wo möglich werden voll gemanagte Cloud Services bevorzugt, um Arbeits- und Personalkosten zu senken. Ausnahmen sind durch ausführliche Analysen schriftlich begründet.",
            EN: "Where possible, fully managed cloud services are preferred to reduce labor and personnel costs. Exceptions are documented with thorough analyses."
        },
        value: 0,
        weight: 1
    }],
    domain: "Kostenoptimierung",
    id: "K03"
};

const q4: Question = {
    title: {
        DE: "Wie optimieren Sie Rechenkosten?",
        EN: "How do you optimize compute costs?"
    },
    answers: [{
        text: {
            DE: "Es werden reservierte Kapazitäten eingekauft, um signifikante Rabatte auf Rechenkosten zu erhalten.",
            EN: "Reserved capacities are purchased to obtain significant discounts on compute costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es wird Auto-Scaling eingesetzt, um die Rechenkapazitäten automatisch an den tatsächlichen Bedarf anzupassen und somit Kosten für ungenutzte Ressourcen zu vermeiden.",
            EN: "Auto-scaling is used to automatically adjust compute capacities to actual demand, avoiding costs for unused resources."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die eingesetzten Instanz-Typen (z.B. VMs) sind auf Effizienz und für ihren Workload optimiert, wodurch die Performance pro Kosten-Einheit maximiert wird.",
            EN: "The instance types used (e.g., VMs) are optimized for efficiency and their workloads, maximizing performance per cost unit."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für weniger kritische oder unterbrechbare Workloads werden Spot-Instances genutzt, um Kosten zu reduzieren.",
            EN: "Spot instances are used for less critical or interruptible workloads to reduce costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Für sehr unregelmäßige oder geringfügige Workloads werden Serverless Dienste (z. B. Azure Functions) eingesetzt, um die Kosten zu optimieren.",
            EN: "Serverless services (e.g., Azure Functions) are used for highly irregular or minor workloads to optimize costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Serverless Dienste (z.B. AWS Lambda) unterliegen einem Performance-Tuning und Optimierung der Code-Ausführung, um Laufzeiten zu minimieren und damit die Kosten zu reduzieren.",
            EN: "Serverless services (e.g., AWS Lambda) undergo performance tuning and code execution optimization to minimize runtimes and reduce costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Kostengünstige Regionen werden bei der Bereitstellungsauswahl berücksichtigt, sofern Governance Regeln und Performance Ziele dies erlauben.",
            EN: "Cost-effective regions are considered in the deployment selection as long as governance rules and performance goals permit."
        },
        value: 0,
        weight: 1
    }],
    domain: "Kostenoptimierung",
    id: "Z04"
};

const q5: Question = {
    title: {
        DE: "Wie optimieren Sie Netzwerkkosten?",
        EN: "How do you optimize network costs?"
    },
    answers: [{
        text: {
            DE: "Datenübertragungen werden optimiert, um die über das Netzwerk gesendeten Datenmengen zu reduzieren und so Kosten zu sparen (z.B. durch effiziente Protokolle wie gRPC zwischen Microservices).",
            EN: "Data transfers are optimized to reduce the amount of data sent over the network, saving costs (e.g., through efficient protocols like gRPC between microservices)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden Content Delivery Networks verwendet, um Inhalte näher an die Endbenutzer zu bringen, was die Netzwerklatenz verringert und die Datenübertragungskosten senkt.",
            EN: "Content Delivery Networks are used to bring content closer to end-users, reducing network latency and lowering data transfer costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Netzwerkdienste werden unter Rücksicht von Kosten-Nutzen-Analysen ausgewählt. Es werden dedizierte Verbindungen (z.B. AWS Direct Connect) genutzt, falls diese wirtschaftlich sinnvoll sind.",
            EN: "Network services are selected based on cost-benefit analyses. Dedicated connections (e.g., AWS Direct Connect) are used if economically sensible."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Wenn Zuverlässigkeitsziele dies erlauben, werden durch die Platzierung von Ressourcen in derselben Availability Zone oder Region zusätzliche Kosten für Datenübertragungen vermieden.",
            EN: "If reliability goals permit, placing resources in the same availability zone or region avoids additional data transfer costs."
        },
        value: 0,
        weight: 1
    }],
    domain: "Kostenoptimierung",
    id: "Z05"
};

const q6: Question = {
    title: {
        DE: "Wie optimieren Sie Speicher- und Datenkosten?",
        EN: "How do you optimize storage and data costs?"
    },
    answers: [{
        text: {
            DE: "Es werden Lebenszyklusmanagement-Policies genutzt, um Daten automatisch zwischen Speicherklassen zu verschieben oder nicht mehr benötigte Daten zu archivieren oder zu löschen (z.B. Life Cycle Management in AWS S3 oder Azure Blob Storage).",
            EN: "Lifecycle management policies are used to automatically move data between storage classes or archive/delete unneeded data (e.g., Lifecycle Management in AWS S3 or Azure Blob Storage)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Caching-Mechanismen werden eingesetzt, um häufig abgerufene Daten schnell bereitzustellen und so die Last auf Datenbanken und die damit verbundenen Kosten zu reduzieren.",
            EN: "Caching mechanisms are used to quickly provide frequently retrieved data, reducing the load on databases and associated costs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Durch die Optimierung von Abfrageanforderungen (Queries) werden Datenmengen reduziert, die verarbeitet und übertragen werden müssen.",
            EN: "By optimizing query requests, the amount of data that needs to be processed and transferred is reduced."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Query Performance wird durch Tools automatisch überwacht und wenn möglich optimiert (z. B. Azure SQL Database Query Performance Insight).",
            EN: "Query performance is automatically monitored and optimized by tools (e.g., Azure SQL Database Query Performance Insight)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden kostengünstige Speicheroptionen für weniger häufig benötigte Daten genutzt (z.B. Amazon S3 Glacier, Azure Blob Storage Cool Tier, Blob Storage statt Analytics Workspace für alte Logs).",
            EN: "Cost-effective storage options are used for less frequently accessed data (e.g., Amazon S3 Glacier, Azure Blob Storage Cool Tier, Blob Storage instead of Analytics Workspace for old logs)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die eingesetzte Datenbanktechnologie passt zum tatsächlichen Workload und den tatsächlichen Anforderungen (z.B. read- oder write-optimiert, noSQL oder SQL) des Geschäftsmodells.",
            EN: "The chosen database technology matches the actual workload and requirements (e.g., read or write-optimized, NoSQL or SQL) of the business model."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Redundanz, z. B. Georeplikation von Ressourcen wird unter Rücksicht der definierten Zuverlässigkeits- und Recoveryziele auf das notwendige Minimum gesenkt.",
            EN: "Redundancy, such as geo-replication of resources, is minimized to the necessary extent considering defined reliability and recovery objectives."
        },
        value: 0,
        weight: 1
    }],
    domain: "Kostenoptimierung",
    id: "Z06"
};

const q7: Question = {
    title: {
        DE: "Wie optimieren Sie Log & Loganalyse Kosten?",
        EN: "How do you optimize log and log analysis costs?"
    },
    answers: [{
        text: {
            DE: "Logging-Tools sind so konfiguriert, dass nur relevante Log-Daten erfasst werden, um die Menge der gespeicherten und analysierten Daten zu minimieren.",
            EN: "Logging tools are configured to capture only relevant log data to minimize the amount of stored and analyzed data."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Wahl des eingesetzten Log Analyse Tools (z.B. Amazon CloudWatch, Azure AppInsights, Loki) erfolgt unter bewusster Abwägung ihres Anwendungsprofils, ihrer Möglichkeiten und ihrer zu erwartenden Kostenstruktur und Betriebsaufwands.",
            EN: "The choice of log analysis tool (e.g., Amazon CloudWatch, Azure AppInsights, Loki) is made considering its application profile, capabilities, expected cost structure, and operational effort."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Speicher- und Aufbewahrungsrichtlinien für Logs sind angepasst, um sicherzustellen, dass Daten nur so lange wie nötig aufbewahrt werden.",
            EN: "Storage and retention policies for logs are adjusted to ensure that data is only kept as long as necessary."
        },
        value: 0,
        weight: 1
    }],
    domain: "Kostenoptimierung",
    id: "Z07"
};

const costOptimizationQuestions = [q1, q2, q3, q4, q5, q6, q7];

export default costOptimizationQuestions;
