import { useContext } from "react";
import AppContext from "../../state/AppContext";
import useScoreCalculation, { AuditScore, getMatchingDomain } from "../results/useScoreCalculation";
import SummarySection from "./summarySection.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import labels from "../../labels/labels";

const Summary = () => {
    const appContext = useContext(AppContext)
    const {language} = appContext.appState
    const auditScore = useScoreCalculation()

    const handlePrint = () => {
        window.print();
      };

    const handleDownload = () => {
        const data = appContext.appState
        const json = JSON.stringify(data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "cloud_assessment_save.json";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    

    return <section className="col">
        <div className="summary row">
            <SummarySection domain={'Zuverlässigkeit'} auditScore={auditScore}/>
            <SummarySection domain={'Sicherheit'} auditScore={auditScore}/>
            <SummarySection domain={'Kostenoptimierung'} auditScore={auditScore}/>
            <SummarySection domain={'Operationale Exzellenz'} auditScore={auditScore}/>
            <SummarySection domain={'Leistungseffizienz'} auditScore={auditScore}/>
        </div>

        <section className="p-4 mt-[var(--spacing-md)] bg-gray-100 rounded-lg shadow-md">
      <div className="flex justify-center space-x-[var(--spacing-md)]">
        <button 
          onClick={handlePrint} 
          className="py-2 px-4 rounded transition space-x-[var(--spacing-sm)]"
        >
          <FontAwesomeIcon icon={faPrint} />
          <span>{labels.print[language]}</span>
        </button>
        <button 
          onClick={handleDownload} 
          className="py-2 px-4 rounded transition space-x-[var(--spacing-sm)]"
        >
          <FontAwesomeIcon icon={faDownload} />
          <span>{labels.downloadConfig[language]}</span>
        </button>
      </div>
    </section>

        </section>
}




export default Summary;