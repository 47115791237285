import React, { useCallback, useContext, useState } from 'react';
import Question from '../questions/Question';
import AppContext from '../../state/AppContext';
import { faEye, faPencil, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuestionNoteField from './question.notefield.component';
import AnswerFormField from './question.answer.component';
import labels from '../../labels/labels';

interface QuestionFormFieldProps {
    question: Question;
}

const QuestionFormField: React.FC<QuestionFormFieldProps> = ({ question }) => {

    const appContext= useContext(AppContext)

    const {language} = appContext.appState

    const [showNoteField, setShowNoteField] = useState<boolean>(!!question.note)

    const toggleNoteField = () => {
        setShowNoteField(prev => !prev)
    }
    
    const ignoreQuestion = () => {
        appContext.setAppState(previousState => {
            const newState = {...previousState}
            newState.questions.find(q => q.id === question.id)!.ignored = true
            return newState;
        })
    }

    const unignoreQuestion = () => {
        appContext.setAppState(previousState => {
            const newState = {...previousState}
            newState.questions.find(q => q.id === question.id)!.ignored = false
            return newState;
        })
    }

    return (
        <section className={`question bg-white rounded-xl shadow-md p-8 mb-4 mx-auto ${question.ignored ? 'question--ignored' : ''}`}>
            <h1 className="text-lg font-bold mb-4">
                {question.title[language]}
            </h1>
            {showNoteField && <QuestionNoteField question={question} />}
            <div className="question--actions">
                {!question.ignored ? <button className="ignore--question space-x-[var(--spacing-sm)]" onClick={ignoreQuestion}>
                    <FontAwesomeIcon icon={faX} />
                    <span>{labels.ignoreQuestion.ignore[language]}</span>
                </button>: <button className="ignore--question space-x-[var(--spacing-sm)]" onClick={unignoreQuestion}>
                <FontAwesomeIcon icon={faEye} />
                    <span>{labels.ignoreQuestion.unignore[language]}</span>
                </button>}
                <button className="space-x-[var(--spacing-sm)]" onClick={toggleNoteField}>
                    <FontAwesomeIcon icon={faPencil} />
                    <span>{labels.note[language]}</span>
                </button>
            </div>
            <div className='answers space-y-4'>
                {question.answers.map((answer) => {
                    return (
                        <AnswerFormField answer={answer} questionId={question.id}/>
                    )}
                )}
            </div>
        </section>
    );
};

export default QuestionFormField;
