import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';

Chart.register(ArcElement, Tooltip);

interface DonutChartProps {
  data: [number, number]
}

const DonutChart: React.FC<DonutChartProps> = ({ data }) => {
  const chartData = {
    datasets: [
      {
        data: data,
        backgroundColor: ['rgb(25,60,110)', 'rgb(255,255,255)'],
        hoverBackgroundColor: ['rgb(15,40,180)', 'rgb(235,235,255)']
      }
    ]
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div className="doughnut">
      <Doughnut data={chartData} options={options} />
    </div>
  );
};

export default DonutChart;