import React, { useContext } from 'react';
import FrameworkDomain, { domains } from '../FrameworkDomain';
import AppContext from '../../state/AppContext';
import UploadConfig from './uploadConfig';
import SwitchLanguage from './switchLanguage';
import labels from '../../labels/labels';

const DomainNavigation: React.FC = () => {
    const appContext = useContext(AppContext)

    const {currentFrameworkDomain, displaying, language} = appContext.appState

    const navigateTo = (domain: FrameworkDomain) => {
        appContext.setAppState(previousState => ({...previousState, displaying: 'questions', currentFrameworkDomain: domain}))
    }

    const showSummary = () => {
        appContext.setAppState(previousState => ({...previousState, displaying: 'summary'}))
    }

    return (
        <section className="domainNavigation">
            <SwitchLanguage/>
            <UploadConfig/>
            {domains.map((domain, index) => { 
                const isSelected =  domain === currentFrameworkDomain && displaying === 'questions'
                return (<button
                    className={`${isSelected ? 'font-bold':''}`}
                    key={index}
                    onClick={() => navigateTo(domain)}
                    >
                    {Object.values(labels.navigation).find(navPoint => navPoint["DE"] === domain)![language]} {/**TODO we must make the domain fully english for this to work properly. This is just a workaround */}
                </button>)
            })}
            <button 
                className={`${displaying === 'summary' ? 'font-bold':''}`}
                onClick={() => showSummary()}
            >
                {labels.navigation.totalRating[language]}
            </button>
        </section>
    );
};

export default DomainNavigation;
