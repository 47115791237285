import Question from "./Question";

const q1: Question = {
    title: {
        DE: "Welche Entwurfsüberlegungen haben Sie im Hinblick auf die Leistungseffizienz Ihres Workloads angestellt?",
        EN: "What considerations have been done regarding the performance efficiency of your workload?"},
    answers: [{
        text: {
            DE: "Es wurden während der Anwendungsentwurfsphase geeignete Datenspeicher für den Workload ausgewählt.",
            EN: "Appropriate data storage solutions were selected for the workload during the application design phase."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Um die Anwendungsleistung zu optimieren, wurden die Komponenten so platziert, dass unnötige Netzwerkzugriffe vermieden werden.",
            EN: "To optimize application performance, components were placed to avoid unnecessary network access."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Wenn sehr starke Read Anforderungen vorhanden sind, werden diese durch effiziente architektonische Strukturen behandelt (z. B. Command Query Segregation).",
            EN: "If high read demands exist, they are handled by efficient architectural structures (e.g., Command Query Segregation)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Wenn durch Leistungsziele erfordert, werden asynchrone Antwortpatterns eingesetzt, um eine hohe Responsivität für Endnutzer zu erzielen.",
            EN: "If required by performance goals, asynchronous response patterns are used to achieve high responsiveness for end-users."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Caching Systeme werden eingesetzt, um Latenzen zu verringern (z. B. Cache-Aside Pattern).",
            EN: "Caching systems are used to reduce latency (e.g., Cache-Aside Pattern)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die eingesetzten Caching Systeme arbeiten mit verteilten Knoten.",
            EN: "The caching systems in use operate with distributed nodes."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Rechenressourcen werden nach einer angemessenen Kapazitätsplanung erstellt.",
            EN: "Computing resources are created following appropriate capacity planning."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Im Lebenszyklus einer Ressource erfolgt eine Korrektur von tatsächlicher und erwarteter Kapazitätsforderung.",
            EN: "During the lifecycle of a resource, adjustments are made between actual and expected capacity demands."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Codelogik wird regelmäßig geprüft auf ineffiziente Algorithmen in kritischen Pfaden, welche dann durch effizientere Implementierungen ersetzt werden.",
            EN: "Code logic is regularly reviewed for inefficient algorithms in critical paths, which are then replaced with more efficient implementations."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Datenbank Operationen werden durch Indexierung beschleunigt.",
            EN: "Database operations are accelerated through indexing."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Datenbank Abfragen werden mit Sollwerten abgeglichen, auf ihre Performanz geprüft und optimiert.",
            EN: "Database queries are benchmarked against targets, tested for performance, and optimized."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Wenn SQL im Einsatz ist, werden automatische Query Optimierungstools der Cloud Provider eingesetzt, um die Performance mit geringem Aufwand zu verbessern.",
            EN: "If SQL is in use, automatic query optimization tools from cloud providers are used to improve performance with minimal effort."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Im Falle erhöhter Workloads erfolgt eine automatische Skalierung und Anpassung der Rechenressourcen.",
            EN: "In the event of increased workloads, automatic scaling and adjustment of computing resources are performed."
        },
        value: 0,
        weight: 1
    }],
    domain: "Leistungseffizienz",
    id: "L01"
};

const q2: Question = {
    title: {
        DE: "Welche Überlegungen haben Sie im Hinblick auf die Leistungseffizienz in ihrem Netzwerkstapel angestellt?",
        EN: 'What considerations have been done in regards to the performance efficiency of your network stack?'},
    answers: [{
        text: {
            DE: "Latenzen werden minimiert durch die Optimierung der Datenwege und die Auswahl von Netzwerkdiensten, die eine geringe Latenz und hohe Bandbreite bieten.",
            EN: "Latencies are minimized by optimizing data paths and selecting network services that offer low latency and high bandwidth."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Load Balancer werden eingesetzt, um Anfragen intelligent auf mehrere Server gleichmäßig zu verteilen.",
            EN: "Load balancers are used to intelligently distribute requests evenly across multiple servers."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Durch die Implementierung von Datenkomprimierung und effektiven Caching-Strategien reduzieren wir die übertragenen Datenmengen und beschleunigen die Antwortzeiten.",
            EN: "By implementing data compression and effective caching strategies, we reduce the amount of data transmitted and speed up response times."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden möglichst effiziente Kommunikationsprotokolle eingesetzt, um eine möglichst effiziente Kommunikation sicherzustellen (z. B. gRPC zwischen Microservices, GraphQL statt mehreren REST Calls).",
            EN: "The most efficient communication protocols are used to ensure optimal communication (e.g., gRPC between microservices, GraphQL instead of multiple REST calls)."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Netzwerkinfrastruktur bietet ausreichende Bandbreitenkapazität, um Spitzenlasten ohne Leistungseinbußen bewältigen zu können.",
            EN: "The network infrastructure provides sufficient bandwidth capacity to handle peak loads without performance degradation."
        },
        value: 0,
        weight: 1
    }],
    domain: "Leistungseffizienz",
    id: "L02"
};

const q3: Question = {
    title: {
        DE: "Wie bewerten Sie die Performance Ihres Workloads?",
        EN: "How do you evaluate the performance of your workload?"},
    answers: [{
        text: {
            DE: "Es wurden Sollwerte definiert, innerhalb derer ein API-/Serviceendpunkt komplett geantwortet haben muss.",
            EN: "Target values have been defined within which an API/service endpoint must fully respond."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es wurden Sollwerte für die Latenz zwischen den Subsystemen (z.B. Microservices) unseres Workloads festgelegt.",
            EN: "Target values for latency between subsystems (e.g., microservices) of our workload have been established."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es wurden Sollwerte dafür festgelegt, wie effizient eine Datenbankabfrage sein muss.",
            EN: "Target values have been set for how efficient a database query must be."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Der Einhalt der erforderten Mindestleistungen für identifizierte Work- und Userflows werden automatisch geprüft, z. B. mittels Pipelines, Tools oder Monitoring Jobs.",
            EN: "Compliance with the required minimum performance for identified work and user flows is automatically checked, e.g., using pipelines, tools, or monitoring jobs."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Frontend Leistungen werden mit Tools wie z. B. Lighthouse regelmäßig geprüft. Sollwerte für die gängigen Performance Metriken (Time to first byte, full page load, time to first interaction) sind definiert.",
            EN: "Frontend performance is regularly checked with tools such as Lighthouse. Target values for common performance metrics (time to first byte, full page load, time to first interaction) are defined."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Es werden regelmäßige Performance-Benchmarks durchgeführt, um die Leistung der Anwendung über die Zeit zu beobachten und zu vergleichen.",
            EN: "Regular performance benchmarks are conducted to monitor and compare the application's performance over time."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Leistungssollwerte richten sich nach der Art des Geräts und/oder der Verbindung.",
            EN: "Performance targets are set based on the type of device and/or connection."
        },
        value: 0,
        weight: 1
    }],
    domain: "Leistungseffizienz",
    id: "L03"
};

const q4: Question = {
    title: {
        DE: "Bei interregionalen Workloads: Wie stellen sie in allen Zielregionen niedrige Latenzen sicher?",
        EN: "In case of multiregional workloads: how do you ensure low latencies for all target regions?"},
    answers: [{
        text: {
            DE: "Der Workload wird in mehreren Regionen bereitgestellt.",
            EN: "The workload is deployed in multiple regions."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Die Regionen wurden anhand von Standort, Benutzernähe und Ressourcentypverfügbarkeit ausgewählt.",
            EN: "Regions were selected based on location, proximity to users, and availability of resource types."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Ein CDN wird zur Ausgabe von statischen Inhalten genutzt.",
            EN: "A CDN is used to deliver static content."
        },
        value: 0,
        weight: 1
    }, {
        text: {
            DE: "Workloads verfügen in allen verwendeten Regionen über dieselben Leistungs- und Skalierungs-SKUs, die derzeit in der Primärregion genutzt werden, oder zumindest ein durch ein abweichendes Nutzungsprofil begründetes Niveau.",
            EN: "Workloads in all used regions have the same performance and scaling SKUs as currently used in the primary region or at least a level justified by a different usage profile."
        },
        value: 0,
        weight: 1
    }],
    domain: "Leistungseffizienz",
    id: "L04"
};

const performanceQuestions: Question[] = [q1, q2, q3, q4];

export default performanceQuestions;
