import {createContext, useState} from "react";
import Question from "../questionaire/questions/Question";
import questions from "../questionaire/questions/allQuestions";
import type FrameworkDomain from "../questionaire/FrameworkDomain";
import packageJson from '../../package.json' // this is the default place to change versioning. Importing it to avoid having to document the version of the project twice

export const defaultAppState: AppState = {
    questions,
    currentFrameworkDomain: "Zuverlässigkeit",
    displaying: 'questions',
    mainVersion: packageJson.version,
    language: 'DE'
}

export const AppContext = createContext<AppStore>({appState: defaultAppState, setAppState: () => {}})

interface AppStore {
    appState: AppState,
    setAppState: React.Dispatch<React.SetStateAction<AppState>>;
}

export interface AppState {
    questions: Question[],
    currentFrameworkDomain: FrameworkDomain,
    displaying: 'questions' | 'summary',
    language: 'DE' | 'EN'
    mainVersion: string //could be useful to reject saved configs or similar
}
 
export default AppContext;