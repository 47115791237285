import { useContext } from "react";
import AppContext from "../state/AppContext";
import QuestionFormField from "./form/question.component";

const AuditQuestionaire = () => {
    const {appState} = useContext(AppContext)
    return <main className="auditQuestionaire">
        {appState.questions.filter(question => question.domain === appState.currentFrameworkDomain).map(question => {
            return<QuestionFormField key={question.id} question={question}/>
        })}
    </main>
}
 
export default AuditQuestionaire;