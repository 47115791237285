import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import AppContext, { AppState } from '../../state/AppContext';

const UploadConfig: React.FC = () => {

    const appContext = useContext(AppContext)

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const content = e.target?.result as string;
            try {
              const loadedConfiguration = JSON.parse(content) as AppState
              if(!loadedConfiguration.currentFrameworkDomain || !loadedConfiguration.displaying || !loadedConfiguration.questions){
                throw new Error('no valid configuration provided')
              }
              appContext.setAppState(loadedConfiguration);
            } catch (error) {
              console.error('Fehler beim Parsen der JSON-Datei:', error);
            }
          };
          reader.readAsText(file);
        }
      };

  return (
    <div className="flex justify-center items-center" title="Bestehende Konfiguration hochladen">
      <label className="bg-[var(--color-accent)] border  text-white py-2 px-4 rounded transition cursor-pointer flex items-center">
        <FontAwesomeIcon icon={faUpload} />
        <span></span>
        <input type="file" accept=".json" onChange={handleFileUpload} className="hidden" />
      </label>
    </div>
  );
};

export default UploadConfig;
